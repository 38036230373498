// src/components/statistics/NotificationSettings.jsx
import React from 'react';
import { 
  Card, 
  Title, 
  Text, 
  Switch,
  Dialog,
  DialogPanel,
  Button
} from "@tremor/react";
import PropTypes from 'prop-types';

export const NotificationSettings = React.memo(({ 
  settings, 
  isOpen,
  onClose,
  onSave 
}) => {
  const [tempSettings, setTempSettings] = React.useState(settings);

  React.useEffect(() => {
    setTempSettings(settings);
  }, [isOpen, settings]);

  const handleChange = (key, value) => {
    setTempSettings(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const handleSave = () => {
    onSave(tempSettings);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} static={true}>
      <DialogPanel className="bg-white rounded-lg shadow-xl min-w-[600px]">
        <div className="max-h-[80vh] overflow-y-auto">
          {/* Header */}
          <div className="px-6 py-4 border-b border-gray-200">
            <div className="flex justify-between items-center">
              <Title className="text-lg font-semibold">Notification Settings</Title>
              <button
                onClick={onClose}
                className="p-1 hover:bg-gray-100 rounded-full transition-colors"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
            </div>
          </div>

          <div className="px-6 py-4 space-y-6">
            {/* Enable/Disable Alerts */}
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <span className="text-sm font-medium text-gray-700">Enable Price Alerts</span>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={tempSettings.enabled}
                    onChange={e => handleChange('enabled', e.target.checked)}
                    className="sr-only peer"
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 
                    peer-focus:ring-blue-300 rounded-full peer 
                    peer-checked:after:translate-x-full peer-checked:after:border-white 
                    after:content-[''] after:absolute after:top-[2px] after:left-[2px] 
                    after:bg-white after:border-gray-300 after:border after:rounded-full 
                    after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600">
                  </div>
                </label>
              </div>
              <Text className="text-gray-500 text-sm">Receive notifications about price changes</Text>
            </div>

            {tempSettings.enabled && (
              <>
                {/* DCA Settings */}
                <div className="space-y-3">
                  <Text className="font-medium text-gray-900">DCA Alert Settings</Text>
                  <div className="bg-gray-50 rounded-lg p-4">
                    <div className="grid grid-cols-2 gap-6">
                      <div>
                        <Text className="mb-2">Price Drop Threshold (%)</Text>
                        <input
                          type="number"
                          value={tempSettings.dcaThreshold}
                          onChange={e => handleChange('dcaThreshold', Number(e.target.value))}
                          min="0"
                          step="0.1"
                          className="w-full px-3 py-2 rounded-md border border-gray-300 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 bg-white"
                        />
                      </div>
                      <div>
                        <Text className="mb-2">Alert Interval (minutes)</Text>
                        <input
                          type="number"
                          value={tempSettings.dcaAlertInterval}
                          onChange={e => handleChange('dcaAlertInterval', Number(e.target.value))}
                          min="1"
                          className="w-full px-3 py-2 rounded-md border border-gray-300 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 bg-white"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Take Profit Settings */}
                <div className="space-y-3">
                  <Text className="font-medium text-gray-900">Take Profit Alert Settings</Text>
                  <div className="bg-gray-50 rounded-lg p-4">
                    <div className="grid grid-cols-2 gap-6">
                      <div>
                        <Text className="mb-2">Profit Threshold (%)</Text>
                        <input
                          type="number"
                          value={tempSettings.profitThreshold}
                          onChange={e => handleChange('profitThreshold', Number(e.target.value))}
                          min="0"
                          step="0.1"
                          className="w-full px-3 py-2 rounded-md border border-gray-300 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 bg-white"
                        />
                      </div>
                      <div>
                        <Text className="mb-2">Alert Interval (minutes)</Text>
                        <input
                          type="number"
                          value={tempSettings.profitAlertInterval}
                          onChange={e => handleChange('profitAlertInterval', Number(e.target.value))}
                          min="1"
                          className="w-full px-3 py-2 rounded-md border border-gray-300 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 bg-white"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Last Sell Price Settings */}
                <div className="space-y-3">
                  <Text className="font-medium text-gray-900">Last Sell Price Alert Settings</Text>
                  <div className="bg-gray-50 rounded-lg p-4">
                    <div className="grid grid-cols-2 gap-6">
                      <div>
                        <Text className="mb-2">Price Drop from Last Sell (%)</Text>
                        <input
                          type="number"
                          value={tempSettings.lastSellDropThreshold}
                          onChange={e => handleChange('lastSellDropThreshold', Number(e.target.value))}
                          min="0"
                          step="0.1"
                          className="w-full px-3 py-2 rounded-md border border-gray-300 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 bg-white"
                        />
                      </div>
                      <div>
                        <Text className="mb-2">Alert Interval (minutes)</Text>
                        <input
                          type="number"
                          value={tempSettings.lastSellAlertInterval}
                          onChange={e => handleChange('lastSellAlertInterval', Number(e.target.value))}
                          min="1"
                          className="w-full px-3 py-2 rounded-md border border-gray-300 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 bg-white"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          {/* Footer */}
          <div className="px-6 py-4 border-t border-gray-200 flex justify-end">
            <Button 
              onClick={handleSave}
              className="bg-blue-600 text-white px-6 hover:bg-blue-700"
            >
              Save Changes
            </Button>
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  );
});

NotificationSettings.propTypes = {
  settings: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    dcaThreshold: PropTypes.number.isRequired,
    dcaAlertInterval: PropTypes.number.isRequired,
    profitThreshold: PropTypes.number.isRequired,
    profitAlertInterval: PropTypes.number.isRequired,
    lastSellDropThreshold: PropTypes.number.isRequired,
    lastSellAlertInterval: PropTypes.number.isRequired
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

NotificationSettings.displayName = 'NotificationSettings';