import React, { useState, useEffect } from 'react';
import { Card, Title, Divider } from "@tremor/react";
import { api } from '../services/api';

export const Settings = () => {
  const [settings, setSettings] = useState(() => {
    const defaultSettings = {
      googleSheetId: '',
      telegramBotToken: '',
      telegramChatId: '',
      scanInterval: 5,
      notificationDelay: 5,
      enableTelegram: true,
      timeRange: {
        type: 'minutes',
        minutes: 10,
        startDate: '',
        endDate: ''
      }
    };

    try {
      const savedSettings = localStorage.getItem('botSettings');
      if (savedSettings) {
        const parsedSettings = JSON.parse(savedSettings);
        return {
          ...defaultSettings,
          ...parsedSettings,
          timeRange: {
            ...defaultSettings.timeRange,
            ...(parsedSettings.timeRange || {})
          }
        };
      }
      return defaultSettings;
    } catch (error) {
      console.error('Error loading settings from localStorage:', error);
      return defaultSettings;
    }
  });

  const [loading, setLoading] = useState(false);
  const [loadingConfig, setLoadingConfig] = useState(false);
  const [message, setMessage] = useState({ text: '', type: '' });

  useEffect(() => {
    if (message.text) {
      const timer = setTimeout(() => {
        setMessage({ text: '', type: '' });
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : 
                    type === 'number' ? parseInt(value) || value : 
                    value;
                    
    const newSettings = {
      ...settings,
      [name]: newValue
    };
    setSettings(newSettings);
    localStorage.setItem('botSettings', JSON.stringify(newSettings));
  };

  const handleTimeRangeChange = (type) => {
    const newSettings = {
      ...settings,
      timeRange: {
        ...settings.timeRange,
        type
      }
    };
    setSettings(newSettings);
    localStorage.setItem('botSettings', JSON.stringify(newSettings));
  };

  const handleTimeRangeValueChange = (e) => {
    const { name, value } = e.target;
    const newSettings = {
      ...settings,
      timeRange: {
        ...settings.timeRange,
        [name]: value
      }
    };
    setSettings(newSettings);
    localStorage.setItem('botSettings', JSON.stringify(newSettings));
  };

  const loadCurrentSettings = async () => {
    setLoadingConfig(true);
    setMessage({ text: '', type: '' });

    try {
      const response = await api.getCurrentSettings();
      if (response.data) {
        const newSettings = {
          googleSheetId: response.data.googleSheetId || '',
          telegramBotToken: response.data.telegramBotToken || '',
          telegramChatId: response.data.telegramChatId || '',
          scanInterval: response.data.scanInterval || 5,
          notificationDelay: response.data.notificationDelay || 5,
          enableTelegram: response.data.enableTelegram !== false,
          timeRange: {
            type: 'minutes',
            minutes: response.data.tradeHistory || 10,
            startDate: '',
            endDate: ''
          }
        };
        setSettings(newSettings);
        localStorage.setItem('botSettings', JSON.stringify(newSettings));
        setMessage({ text: 'Settings loaded successfully', type: 'success' });
      }
    } catch (error) {
      setMessage({ 
        text: error.response?.data?.message || 'Error loading settings', 
        type: 'error' 
      });
    } finally {
      setLoadingConfig(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage({ text: '', type: '' });

    try {
      await api.updateSettings(settings);
      setMessage({ text: 'Settings updated successfully', type: 'success' });
    } catch (error) {
      setMessage({ 
        text: error.response?.data?.message || 'Error updating settings', 
        type: 'error' 
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-4">
      <Card>
        <div className="flex justify-between items-center">
		  <Title>Configuration Settings</Title>
		  <div className="flex space-x-4">
			<button
			  onClick={loadCurrentSettings}
			  disabled={loadingConfig}
			  className={`px-4 py-2 rounded-md shadow-sm text-sm font-medium text-white 
				${loadingConfig 
				  ? 'bg-gray-400 cursor-not-allowed' 
				  : 'bg-blue-600 hover:bg-blue-700'}`}
			>
			  {loadingConfig ? 'Loading...' : 'Load Current Settings'}
			</button>
			<button
			  type="button"
			  onClick={handleSubmit}
			  disabled={loading}
			  className={`px-4 py-2 rounded-md shadow-sm text-sm font-medium text-white 
				${loading 
				  ? 'bg-gray-400 cursor-not-allowed' 
				  : 'bg-green-600 hover:bg-green-700'}`}
			>
			  {loading ? 'Saving...' : 'Save Settings'}
			</button>
		  </div>
		</div>

        <div className={`transition-opacity duration-500 ${message.text ? 'opacity-100' : 'opacity-0'}`}>
          {message.text && (
            <div className={`mt-4 p-3 rounded ${
              message.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
            }`}>
              {message.text}
            </div>
          )}
        </div>

        <form onSubmit={handleSubmit} className="mt-4 space-y-6">
          {/* Time Range Settings */}
          <div className="space-y-4">
            <Title>Time Range Settings</Title>
            <div className="space-y-2">
              <div className="space-x-4">
                <label className="inline-flex items-center">
				  <input
					type="radio"
					className="form-radio"
					checked={settings.timeRange.type === 'minutes'}
					onChange={() => handleTimeRangeChange('minutes')}
					className="text-blue-600"
				  />
				  <span className="ml-2">
					Last <span className="font-medium text-blue-600">{settings.timeRange.minutes}</span> Minutes
				  </span>
				</label>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    className="form-radio"
                    checked={settings.timeRange.type === 'dateRange'}
                    onChange={() => handleTimeRangeChange('dateRange')}
                    className="text-blue-600"
                  />
                  <span className="ml-2">Date Range</span>
                </label>
              </div>

              {settings.timeRange.type === 'minutes' ? (
                <div>
                  <label className="block text-sm font-medium text-gray-700">Minutes</label>
                  <input
                    type="number"
                    name="minutes"
                    value={settings.timeRange.minutes}
                    onChange={handleTimeRangeValueChange}
                    min="1"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                </div>
              ) : (
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Start Date</label>
                    <input
                      type="datetime-local"
                      name="startDate"
                      value={settings.timeRange.startDate}
                      onChange={handleTimeRangeValueChange}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">End Date</label>
                    <input
                      type="datetime-local"
                      name="endDate"
                      value={settings.timeRange.endDate}
                      onChange={handleTimeRangeValueChange}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <Divider />

          {/* Google Sheets Settings */}
          <div className="space-y-4">
            <Title>Google Sheets Settings</Title>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Google Sheet ID
              </label>
              <input
                type="text"
                name="googleSheetId"
                value={settings.googleSheetId}
                onChange={handleChange}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </div>

          <Divider />

          {/* Telegram Settings */}
          <div className="space-y-4">
            <Title>Telegram Settings</Title>
            <div className="flex items-center justify-between">
              <span className="text-sm font-medium text-gray-700">Enable Telegram Notifications</span>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  name="enableTelegram"
                  checked={settings.enableTelegram}
                  onChange={handleChange}
                  className="sr-only peer"
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 
                  peer-focus:ring-blue-300 rounded-full peer 
                  peer-checked:after:translate-x-full peer-checked:after:border-white 
                  after:content-[''] after:absolute after:top-[2px] after:left-[2px] 
                  after:bg-white after:border-gray-300 after:border after:rounded-full 
                  after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
              </label>
            </div>

            {settings.enableTelegram && (
              <>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Telegram Bot Token
                  </label>
                  <input
                    type="text"
                    name="telegramBotToken"
                    value={settings.telegramBotToken}
                    onChange={handleChange}
                    required={settings.enableTelegram}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Telegram Chat ID
                  </label>
                  <input
                    type="text"
                    name="telegramChatId"
                    value={settings.telegramChatId}
                    onChange={handleChange}
                    required={settings.enableTelegram}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                </div>
              </>
            )}
          </div>

          <Divider />

          {/* Advanced Settings */}
          <div className="space-y-4">
            <Title>Advanced Settings</Title>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Scan Interval (minutes)
                </label>
                <input
                  type="number"
                  name="scanInterval"
                  value={settings.scanInterval}
                  onChange={handleChange}
                  min="1"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>

              {settings.enableTelegram && (
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Notification Delay (seconds)
                  </label>
                  <input
                    type="number"
                    name="notificationDelay"
                    value={settings.notificationDelay}
                    onChange={handleChange}
                    min="1"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                </div>
              )}
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
};