import React, { useState, useEffect } from 'react';
import { Card, Text, Metric, Title } from "@tremor/react";
import { api } from '../services/api';
import { StatusCard } from './dashboard/StatusCard';
import { ActivityItem } from './dashboard/ActivityItem';

export const Dashboard = () => {
  const [status, setStatus] = useState({
    isRunning: false,
    lastScan: null,
    totalSymbols: 0,
    pendingMessages: 0,
    recentActivities: []
  });

  const [message, setMessage] = useState({ text: '', type: '' });

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await api.getStatus();
        setStatus(response.data);
        if (message.type === 'error') {
          setMessage({ text: '', type: '' });
        }
      } catch (error) {
        console.error('Error fetching status:', error);
        setMessage({
          text: error.response?.data?.message || 'Error fetching bot status',
          type: 'error'
        });
      }
    };

    fetchStatus();
    const interval = setInterval(fetchStatus, 5000);
    return () => clearInterval(interval);
  }, [message.type]);

  return (
    <div className="p-4 space-y-4">
      {/* Status Messages */}
      {message.text && (
        <div className={`p-4 rounded-md ${
          message.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
        }`}>
          {message.text}
        </div>
      )}

      {/* Status Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <StatusCard
          title="Status"
          isRunning={status.isRunning}
          value={status.isRunning ? "Running" : "Stopped"}
          subtitle={`Last Scan: ${status.lastScan ? new Date(status.lastScan).toLocaleString() : 'N/A'}`}
          decorationColor={status.isRunning ? "green" : "red"}
        />

        <StatusCard
          title="Statistics"
          isRunning={false}
          value={`${status.totalSymbols} Symbols`}
          subtitle={`Pending Messages: ${status.pendingMessages}`}
        />
      </div>

      {/* Recent Activities */}
      <Card>
        <Title>Recent Activities</Title>
        <div className="mt-4 h-[560px] overflow-y-auto pr-2" style={{ scrollbarGutter: 'stable' }}>
          {status.recentActivities && status.recentActivities.length > 0 ? (
            status.recentActivities.map((activity, index) => (
              <ActivityItem
                key={`${activity.timestamp}-${index}`}
                message={activity.message}
                timestamp={activity.timestamp}
              />
            ))
          ) : (
            <Text className="text-gray-500">No recent activities</Text>
          )}
        </div>
      </Card>
    </div>
  );
};