// File mới: src/utils/notificationHelper.js
import { formatNumber } from './format';

export const generatePriceAlerts = (token, lastSellPrice, settings, lastAlertTimes) => {
  // Kiểm tra null/undefined
  if (!token || !settings) {
    console.warn('Missing required parameters in generatePriceAlerts');
    return [];
  }
  
  if (!settings.enabled) return [];
  
  const currentTime = Date.now();
  const alerts = [];

  // Helper để kiểm tra thời gian alert
  const canSendAlert = (type, interval) => {
    if (!lastAlertTimes) return true; // Nếu chưa có lastAlertTimes, cho phép gửi
    const lastTime = lastAlertTimes[`${token.symbol}_${type}`] || 0;
    return (currentTime - lastTime) >= (interval * 60 * 1000);
  };

  // Check DCA opportunity
  if (token.remainingTokens > 0) {
    const priceDiff = ((token.currentPrice - token.averageBuyPrice) / token.averageBuyPrice) * 100;
    
    if (token.currentPrice < token.averageBuyPrice && 
        Math.abs(priceDiff) >= settings.dcaThreshold && 
        canSendAlert('DCA', settings.dcaAlertInterval)) {
      alerts.push({
        type: 'DCA_OPPORTUNITY',
        message: `🔵 ${token.symbol}: Có thể DCA - Giá hiện tại (${token.currentPrice.toFixed(4)}) thấp hơn giá trung bình (${token.averageBuyPrice.toFixed(4)}) ~${Math.abs(priceDiff).toFixed(2)}%`
      });
    }

    // Check take profit opportunity
    if (priceDiff >= settings.profitThreshold && 
        canSendAlert('PROFIT', settings.profitAlertInterval)) {
      alerts.push({
        type: 'TAKE_PROFIT',
        message: `🟢 ${token.symbol}: Có thể chốt lời - Giá hiện tại (${token.currentPrice.toFixed(4)}) cao hơn giá trung bình (${token.averageBuyPrice.toFixed(4)}) ~${priceDiff.toFixed(2)}%`
      });
    }
  }

  // Check last sell price
  if (lastSellPrice && token.currentPrice < lastSellPrice) {
    const priceDiff = ((token.currentPrice - lastSellPrice) / lastSellPrice) * 100;
    if (Math.abs(priceDiff) >= settings.lastSellDropThreshold && 
        canSendAlert('LAST_SELL', settings.lastSellAlertInterval)) {
      alerts.push({
        type: 'PRICE_DROP',
        message: `🔴 ${token.symbol}: Giá hiện tại (${token.currentPrice.toFixed(4)}) thấp hơn lần bán cuối (${lastSellPrice.toFixed(4)}) ~${Math.abs(priceDiff).toFixed(2)}%`
      });
    }
  }

  return alerts;
};