// File: src/components/statistics/TokenCard.jsx
import React, { useEffect } from 'react';
import { Card, Title, Text, Metric, Badge } from "@tremor/react";
import PropTypes from 'prop-types';
import { formatNumber } from '../utils/format';
import { generatePriceAlerts } from '../utils/notificationHelper';

export const TokenCard = React.memo(({ 
  token, 
  lastSellPrice,
  notificationSettings,
  lastAlertTimes,
  onAlert 
}) => {
  console.log('TokenCard Props:', {
    symbol: token.symbol,
    lastSellPrice: lastSellPrice,
    hasLastSellPrice: lastSellPrice !== undefined,
    lastSellPriceType: typeof lastSellPrice,
    tokensSold: token.tokensSold
  });
  
  useEffect(() => {
    const alerts = generatePriceAlerts(
      token, 
      lastSellPrice,
      notificationSettings,
      lastAlertTimes
    );
    
    if (alerts.length > 0 && onAlert) {
      alerts.forEach(alert => onAlert(alert));
    }
  }, [token, lastSellPrice, notificationSettings, lastAlertTimes, onAlert]);
  
  // Tính toán chỉ số so sánh
  const priceCompareAvg = token.remainingTokens > 0 
    ? ((token.currentPrice - token.averageBuyPrice) / token.averageBuyPrice) * 100
    : null;

  return (
    <Card className="space-y-4">
      {/* Token Header */}
      <div className="flex justify-between items-center">
        <Title>{token.symbol}</Title>
        <Text 
          className={`font-bold ${
            (token.totalProfitLoss || 0) > 0 ? 'text-green-600' : 
            (token.totalProfitLoss || 0) < 0 ? 'text-red-600' : 
            'text-gray-600'
          }`}
        >
          {(token.totalProfitLoss >= 0 ? '+' : '') + formatNumber(token.totalProfitLoss)} USDT
        </Text>
      </div>

      {/* Price Alerts & Badges */}
      <div className="space-y-2">
        {token.remainingTokens > 0 ? (
          <div className="flex items-center space-x-2">
            <Badge 
              color={priceCompareAvg >= 0 ? "green" : "blue"}
              size="sm"
            >
              {priceCompareAvg >= 0 ? "Profit" : "DCA"} Zone
            </Badge>
            <Text 
              className={priceCompareAvg >= 0 ? "text-green-600" : "text-blue-600"}
            >
              {priceCompareAvg >= 0 ? "+" : ""}{priceCompareAvg.toFixed(2)}%
            </Text>
          </div>
        ) : (
          <div className="flex items-center space-x-2">
            <Badge 
              color="gray"
              size="sm"
            >
              No Holdings
            </Badge>
          </div>
        )}
      </div>

      {/* Trading Information */}
      <div className="pt-4 border-t space-y-4">
        {/* Tokens Bought & Sold */}
        <div className="flex justify-between items-center">
          <div>
            <Text>Tokens Bought</Text>
            <Metric className="text-blue-600">
              {formatNumber(token.tokensBought)}
            </Metric>
            <Text className="text-sm text-gray-500">
              {formatNumber(token.usdtSpent)} USDT
            </Text>
          </div>
          <div className="text-right">
            <Text>Tokens Sold</Text>
            <Metric className="text-red-600">
              {formatNumber(token.tokensSold)}
            </Metric>
            <Text className="text-sm text-gray-500">
              {formatNumber(token.usdtReceived)} USDT
            </Text>
          </div>
        </div>

        {/* Tokens Remaining & Current Price */}
        <div className="flex justify-between items-center">
          <div>
            <Text>Tokens Remaining</Text>
            <Metric className="text-green-600">
              {formatNumber(token.remainingTokens)}
            </Metric>
          </div>
          <div className="text-right">
            <Text>Current Price</Text>
            <Metric 
              className={`${
                token.currentPrice >= token.averageBuyPrice ? 'text-green-600' : 'text-red-600'
              }`}
            >
              {formatNumber(token.currentPrice, 4)} USDT
            </Metric>
          </div>
        </div>
        
        {/* Avg Buy Price & Current Value */}
        <div className="flex justify-between items-center">
          <div>
            <Text>Avg Buy Price</Text>
            <Metric className="text-blue-600">
              {formatNumber(token.averageBuyPrice, 4)} USDT
            </Metric>
          </div>
          <div className="text-right">
            <Text>Current Value</Text>
            <Metric className={token.currentValue > 0 ? 'text-green-600' : 'text-gray-600'}>
              {formatNumber(token.currentValue)} USDT
            </Metric>
            <Text 
              className={`text-sm ${
                token.totalProfitLoss >= 0 ? 'text-green-500' : 'text-red-500'
              }`}
            >
              {token.totalProfitLoss >= 0 ? '+' : ''}{formatNumber(token.totalProfitLoss)} USDT
            </Text>
          </div>
        </div>
      </div>

      {/* Last Transactions Information */}
<div className="pt-4 border-t text-sm text-gray-500 space-y-2">
  {/* Last Buy Info */}
  <div className="space-y-1">
    <Text className="font-medium">Last Buy:</Text>
    {lastSellPrice?.lastBuy ? (
      <>
        <div className="flex justify-between">
          <Text>Price:</Text>
          <Text>{formatNumber(lastSellPrice.lastBuy.price, 4)} USDT</Text>
        </div>
        <div className="flex justify-between">
          <Text>Quantity:</Text>
          <Text>{formatNumber(lastSellPrice.lastBuy.quantity, 4)}</Text>
        </div>
        <div className="flex justify-between">
          <Text>Time:</Text>
          <Text>{new Date(lastSellPrice.lastBuy.time).toLocaleString()}</Text>
        </div>
      </>
    ) : (
      <Text className="text-gray-400">N/A</Text>
    )}
  </div>

  {/* Last Sell Info */}
  <div className="space-y-1">
    <Text className="font-medium">Last Sell:</Text>
    {lastSellPrice?.lastSell ? (
      <>
        <div className="flex justify-between">
          <Text>Price:</Text>
          <Text>{formatNumber(lastSellPrice.lastSell.price, 4)} USDT</Text>
        </div>
        <div className="flex justify-between">
          <Text>Quantity:</Text>
          <Text>{formatNumber(lastSellPrice.lastSell.quantity, 4)}</Text>
        </div>
        <div className="flex justify-between">
          <Text>Time:</Text>
          <Text>{new Date(lastSellPrice.lastSell.time).toLocaleString()}</Text>
        </div>
      </>
    ) : (
      <Text className="text-gray-400">N/A</Text>
    )}
  </div>
</div>
    </Card>
  );
});

TokenCard.propTypes = {
  token: PropTypes.shape({
    symbol: PropTypes.string.isRequired,
    tokensBought: PropTypes.number.isRequired,
    tokensSold: PropTypes.number.isRequired,
    remainingTokens: PropTypes.number.isRequired,
    usdtSpent: PropTypes.number.isRequired,
    usdtReceived: PropTypes.number.isRequired,
    averageBuyPrice: PropTypes.number.isRequired,
    currentPrice: PropTypes.number.isRequired,
    currentValue: PropTypes.number.isRequired,
    totalProfitLoss: PropTypes.number.isRequired
  }).isRequired,
  notificationSettings: PropTypes.object.isRequired,
  lastAlertTimes: PropTypes.object.isRequired,
  onAlert: PropTypes.func.isRequired,
  lastSellPrice: PropTypes.shape({
    lastSell: PropTypes.shape({
      price: PropTypes.number,
      quantity: PropTypes.number,
      time: PropTypes.string
    }),
    lastBuy: PropTypes.shape({
      price: PropTypes.number,
      quantity: PropTypes.number,
      time: PropTypes.string
    })
}),
};

TokenCard.displayName = 'TokenCard';

export default TokenCard;