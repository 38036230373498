import axios from 'axios';

const BASE_URL = 'https://bnc.zxc.mom/api';

export const api = {
  async getStatus() {
    return axios.get(`${BASE_URL}/status`);
  },

  async startBot(timeRange) {
    // timeRange có thể là:
    // { type: 'minutes', minutes: 10 }
    // hoặc
    // { type: 'dateRange', startDate: '2024-01-01T00:00', endDate: '2024-01-02T00:00' }
    return axios.post(`${BASE_URL}/start`, { timeRange });
  },

  async stopBot() {
    return axios.post(`${BASE_URL}/stop`);
  },

  async getCurrentSettings() {
    return axios.get(`${BASE_URL}/settings`);
  },

  async updateSettings(settings) {
    return axios.post(`${BASE_URL}/settings`, settings);
  },

  async getLogs(params) {
    return axios.get(`${BASE_URL}/logs`, { params });
  },
  
  async getStatistics() {
    return axios.get(`${BASE_URL}/statistics`);
  },

  async getLastSellPrices() {
    return axios.get(`${BASE_URL}/statistics/last-sells`);
  },
  
  async sendNotification(message) {
    return axios.post(`${BASE_URL}/notifications`, { message });
  }
};