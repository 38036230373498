import React, { useState, useEffect, useRef } from 'react';
import { Card, Title } from "@tremor/react";
import { api } from '../services/api';

export const Log = () => {
  const [logs, setLogs] = useState([]);
  const [autoScroll, setAutoScroll] = useState(true);
  const [filter, setFilter] = useState('all');
  const logContainerRef = useRef(null);

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const response = await api.getLogs();
        // Cập nhật logs
        setLogs(response.data);
        
        // Nếu auto-scroll được bật, scroll lên trên cùng
        if (autoScroll && logContainerRef.current) {
          logContainerRef.current.scrollTop = 0;
        }
      } catch (error) {
        console.error('Error fetching logs:', error);
      }
    };

    fetchLogs();
    const interval = setInterval(fetchLogs, 5000);

    return () => clearInterval(interval);
  }, [autoScroll]);

  const filteredLogs = logs.filter(log => {
    if (filter === 'all') return true;
    return log.level === filter;
  });

  const getLogLevelColor = (level) => {
    switch (level) {
      case 'error':
        return 'text-red-600';
      case 'warning':
        return 'text-yellow-600';
      case 'success':
        return 'text-green-600';
      default:
        return 'text-gray-600';
    }
  };

  return (
    <div className="p-4">
      <Card>
        <div className="flex justify-between items-center mb-4">
          <Title>Application Logs</Title>
          <div className="flex space-x-4">
            <select
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            >
              <option value="all">All Logs</option>
              <option value="error">Errors Only</option>
              <option value="info">Info Only</option>
              <option value="success">Success Only</option>
              <option value="warning">Warnings Only</option>
            </select>
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={autoScroll}
                onChange={(e) => setAutoScroll(e.target.checked)}
                className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
              <span className="ml-2 text-sm text-gray-600">Auto-scroll</span>
            </label>
          </div>
        </div>

        <div 
          ref={logContainerRef}
          className="bg-gray-50 rounded-md p-4 h-[600px] overflow-y-auto font-mono text-sm"
        >
          {filteredLogs.length > 0 ? (
            filteredLogs.map((log, index) => (
              <div 
                key={index} 
                className={`py-1 ${getLogLevelColor(log.level)}`}
              >
                <span className="text-gray-500">{new Date(log.timestamp).toLocaleString()}</span>
                {' - '}
                <span className="font-semibold">[{log.level.toUpperCase()}]</span>
                {' '}
                {log.message}
              </div>
            ))
          ) : (
            <div className="text-center text-gray-500">No logs to display</div>
          )}
        </div>
      </Card>
    </div>
  );
};