import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import { Dashboard } from './components/Dashboard';
import { Settings } from './components/Settings';
import { Log } from './components/Log';
import { api } from './services/api';
import Statistics from './components/Statistics';

function App() {
  const [status, setStatus] = useState({
    isRunning: false
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await api.getStatus();
        setStatus({ isRunning: response.data.isRunning });
      } catch (error) {
        console.error('Error fetching status:', error);
      }
    };

    fetchStatus();
    const interval = setInterval(fetchStatus, 5000);
    return () => clearInterval(interval);
  }, []);

  const handleStartBot = async () => {
    setLoading(true);
    try {
      const savedSettings = localStorage.getItem('botSettings');
      if (!savedSettings) {
        alert('Please configure bot settings first');
        return;
      }

      const settings = JSON.parse(savedSettings);
      const timeRange = settings.timeRange;
      
      await api.startBot(timeRange);
      setStatus({ isRunning: true });
    } catch (error) {
      console.error('Error starting bot:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleStopBot = async () => {
    setLoading(true);
    try {
      await api.stopBot();
      setStatus({ isRunning: false });
    } catch (error) {
      console.error('Error stopping bot:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Router>
      <div className="min-h-screen bg-gray-100 flex flex-col">
        {/* Fixed Navigation Bar */}
        <nav className="bg-white shadow fixed top-0 left-0 right-0 z-50">
          <div className="max-w-7xl mx-auto px-4">
            <div className="flex justify-between h-16">
              <div className="flex items-center">
                <div className="flex-shrink-0 flex items-center">
                  <h1 className="text-xl font-bold text-gray-800">Get BNC Transaction</h1>
                </div>
                <div className="ml-6 flex space-x-8">
                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      `inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ${
                        isActive
                          ? 'border-blue-500 text-blue-600'
                          : 'border-transparent text-gray-500 hover:text-gray-700'
                      }`
                    }
                  >
                    Dashboard
                  </NavLink>
                  <NavLink
                    to="/settings"
                    className={({ isActive }) =>
                      `inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ${
                        isActive
                          ? 'border-blue-500 text-blue-600'
                          : 'border-transparent text-gray-500 hover:text-gray-700'
                      }`
                    }
                  >
                    Settings
                  </NavLink>
                  <NavLink
                    to="/logs"
                    className={({ isActive }) =>
                      `inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ${
                        isActive
                          ? 'border-blue-500 text-blue-600'
                          : 'border-transparent text-gray-500 hover:text-gray-700'
                      }`
                    }
                  >
                    Logs
                  </NavLink>
				  <NavLink
					to="/statistics"
					className={({ isActive }) =>
					  `inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ${
						isActive
						  ? 'border-blue-500 text-blue-600'
						  : 'border-transparent text-gray-500 hover:text-gray-700'
					  }`
					}
				  >
					Statistics
				  </NavLink>
                </div>
              </div>

              {/* Control Buttons */}
              <div className="flex items-center space-x-4">
                <button
                  onClick={handleStartBot}
                  disabled={loading || status.isRunning}
                  className={`px-4 py-2 rounded-md text-sm font-medium text-white transition-colors
                    ${loading || status.isRunning
                      ? 'bg-gray-400 cursor-not-allowed'
                      : 'bg-green-600 hover:bg-green-700'}`}
                >
                  {loading ? 'Processing...' : 'Start'}
                </button>
                <button
                  onClick={handleStopBot}
                  disabled={loading || !status.isRunning}
                  className={`px-4 py-2 rounded-md text-sm font-medium text-white transition-colors
                    ${loading || !status.isRunning
                      ? 'bg-gray-400 cursor-not-allowed'
                      : 'bg-red-600 hover:bg-red-700'}`}
                >
                  {loading ? 'Processing...' : 'Stop'}
                </button>
              </div>
            </div>
          </div>
        </nav>

        {/* Main Content with Padding Top for Fixed Nav */}
        <main className="flex-1 max-w-7xl w-full mx-auto py-6 sm:px-6 lg:px-8 mt-16">
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/logs" element={<Log />} />
			<Route path="/statistics" element={<Statistics />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;