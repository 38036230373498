// src/components/Statistics.jsx
import React, { useState, useEffect } from 'react';
import { 
  Card, 
  Title, 
  Text, 
  Grid, 
  Col, 
  Metric, 
  Button 
} from "@tremor/react";
import { api } from '../services/api';
import { TokenCard } from './statistics/TokenCard';
import { NotificationSettings } from './statistics/NotificationSettings';
import { formatNumber } from './utils/format';

const Statistics = () => {
  // States for statistics data
  const [stats, setStats] = useState({
    totalTrades: 0,
    totalUsdtSpent: 0,
    totalUsdtReceived: 0,
    currentPortfolioValue: 0,
    totalProfitLoss: 0,
    tokens: []
  });
  
  // States for price monitoring
  const [lastSellPrices, setLastSellPrices] = useState({});
  const [notificationSettings, setNotificationSettings] = useState(() => {
    const savedSettings = localStorage.getItem('notificationSettings');
    return savedSettings ? JSON.parse(savedSettings) : {
      enabled: true,
      dcaThreshold: 5,
      dcaAlertInterval: 60,
      profitThreshold: 5,
      profitAlertInterval: 60,
      lastSellDropThreshold: 5,
      lastSellAlertInterval: 60
    };
  });
  const [lastAlertTimes, setLastAlertTimes] = useState({});

  // UI States
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reloadInterval, setReloadInterval] = useState(60);
  const [intervalId, setIntervalId] = useState(null);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  // Fetch all data
  const fetchData = async () => {
    try {
      setLoading(true);
      const [statsResponse, lastSellsResponse] = await Promise.all([
        api.getStatistics(),
        api.getLastSellPrices()
      ]);

      console.log('Last Sell Prices:', lastSellsResponse.data);
      setStats(statsResponse.data);
      setLastSellPrices(lastSellsResponse.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.response?.data?.message || 'Error fetching data');
    } finally {
      setLoading(false);
    }
  };

useEffect(() => {
  const fetchNotificationLog = async () => {
    try {
      const response = await api.get('/api/notification-log');
      setLastAlertTimes(response.data);
    } catch (error) {
      console.error('Error fetching notification log:', error);
    }
  };
  fetchNotificationLog();
}, []);

  // Setup auto refresh
  useEffect(() => {
    fetchData();

    if (intervalId) {
      clearInterval(intervalId);
    }

    if (reloadInterval > 0) {
      const newIntervalId = setInterval(fetchData, reloadInterval * 1000);
      setIntervalId(newIntervalId);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [reloadInterval]);

  // Handle notification alerts
  const handleAlert = async (alert, tokenSymbol) => {
  try {
    if (notificationSettings.enabled) {
      const response = await api.sendNotification({
        message: alert.message,
        symbol: tokenSymbol,
        type: alert.type
      });
      
      if (response.data.success) {
        setLastAlertTimes(prev => ({
          ...prev,
          [`${tokenSymbol}_${alert.type}`]: Date.now()
        }));
        console.log('Notification sent:', alert.message);
      } else if (response.status === 429) {
        console.log('Rate limit info:', response.data);
      }
    }
  } catch (error) {
    console.error('Error sending notification:', error);
    if (error.response?.status === 429) {
      console.log('Next allowed time:', error.response.data.nextAllowedTime);
    }
  }
};

  // Handle notification settings save
  const handleSaveSettings = (newSettings) => {
    setNotificationSettings(newSettings);
    localStorage.setItem('notificationSettings', JSON.stringify(newSettings));
  };

  // Loading skeleton
  if (loading && !stats.tokens.length) {
    return (
      <div className="p-4">
        <Card>
          <div className="animate-pulse space-y-4">
            <div className="h-4 bg-gray-200 rounded w-1/4"></div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              {[...Array(4)].map((_, i) => (
                <div key={i} className="space-y-2">
                  <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                  <div className="h-8 bg-gray-200 rounded w-3/4"></div>
                </div>
              ))}
            </div>
          </div>
        </Card>
      </div>
    );
  }

  // Error state
  if (error) {
    return (
      <div className="p-4">
        <Card className="bg-red-50 border border-red-100">
          <div className="flex items-center space-x-2">
            <svg className="w-5 h-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
            </svg>
            <Text className="text-red-600 font-medium">{error}</Text>
          </div>
        </Card>
      </div>
    );
  }

  return (
    <div className="p-4 space-y-6">
      {/* Control Panel */}
      <Card>
	  <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between gap-4">
		<div className="flex flex-col sm:flex-row sm:items-center gap-4">
		  <Text>Auto Refresh Interval:</Text>
		  <div className="flex items-center gap-2">
			<input
			  type="number"
			  min="0"
			  value={reloadInterval}
			  onChange={(e) => setReloadInterval(Number(e.target.value))}
			  className="w-24 px-2 py-1 border rounded-md focus:ring-2 focus:ring-blue-500"
			/>
			<Text>seconds</Text>
		  </div>
		  <Text className="text-gray-500 text-sm">
			(Set to 0 to disable auto refresh)
		  </Text>
		</div>
		<div className="flex flex-col sm:flex-row gap-2">
		  <Button
			onClick={() => setIsSettingsOpen(true)}
			variant="secondary"
			icon={() => (
			  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
				<path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
			  </svg>
			)}
			className="w-full sm:w-auto"
		  >
			Notification Settings
		  </Button>
		  <Button 
			onClick={fetchData}
			className="w-full sm:w-auto"
		  >
			Refresh Now
		  </Button>
		</div>
	  </div>
	</Card>

      {/* Overall Statistics */}
      <Card>
        <Title>Overall Statistics</Title>
        <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          <div>
            <Text>Total Transactions</Text>
            <Metric>{formatNumber(stats.totalTrades, 0)}</Metric>
          </div>
          <div>
            <Text>Total USDT Invested</Text>
            <Metric>{formatNumber(stats.totalUsdtSpent)} USDT</Metric>
          </div>
          <div>
            <Text>Total USDT Received</Text>
            <Metric>{formatNumber(stats.totalUsdtReceived)} USDT</Metric>
          </div>
          <div>
            <Text>Current Portfolio Value</Text>
            <Metric>{formatNumber(stats.currentPortfolioValue)} USDT</Metric>
          </div>
        </div>
        <div className="mt-4 p-4 bg-gray-50 rounded-lg">
          <Text>Total Profit/Loss (including unrealized)</Text>
          <Metric 
            className={stats.totalProfitLoss >= 0 ? 'text-green-600' : 'text-red-600'}
          >
            {(stats.totalProfitLoss >= 0 ? '+' : '') + formatNumber(stats.totalProfitLoss)} USDT
            <span className="text-sm ml-2">
              ({formatNumber(
                (stats.totalProfitLoss / (stats.totalUsdtSpent || 1)) * 100, 2
              )}%)
            </span>
          </Metric>
        </div>
      </Card>

      {/* Token Statistics */}
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
        {(stats.tokens || [])
		  .sort((a, b) => Math.abs(b.totalProfitLoss) - Math.abs(a.totalProfitLoss))
		  .map((token) => {
			const tokenLastSellPrice = lastSellPrices[`${token.symbol}USDT`];
			console.log('Mapping token to card:', {
			  symbol: token.symbol,
			  fullSymbol: `${token.symbol}USDT`,
			  lastSellPrice: tokenLastSellPrice
			});

			return (
			  <TokenCard 
				key={token.symbol} 
				token={token}
				lastSellPrice={tokenLastSellPrice}
				notificationSettings={notificationSettings}
				lastAlertTimes={lastAlertTimes}
				onAlert={(alert) => handleAlert(alert, token.symbol)}
			  />
			);
		  })}
      </div>

      {/* Notification Settings Dialog */}
      <NotificationSettings
        settings={notificationSettings}
        isOpen={isSettingsOpen}
        onClose={() => setIsSettingsOpen(false)}
        onSave={handleSaveSettings}
      />

      {/* Loading Indicator */}
      {loading && (
        <div className="fixed bottom-4 right-4 bg-blue-500 text-white px-4 py-2 rounded-md 
                      shadow-lg animate-pulse">
          Refreshing...
        </div>
      )}
    </div>
  );
};

export default Statistics;