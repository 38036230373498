// File: StatusCard.jsx
import React from 'react';
import { Card, Title, Metric, Text } from "@tremor/react";

export const StatusCard = React.memo(({ 
  title, 
  isRunning, 
  value, 
  subtitle,
  decorationColor 
}) => {
  console.log(`StatusCard rendered: ${title}`); // Debugging render

  return (
    <Card decoration="top" decorationColor={decorationColor}>
      <Title>{title}</Title>
      <Metric className={isRunning ? "text-green-600" : "text-red-600"}>
        {value}
      </Metric>
      {subtitle && <Text>{subtitle}</Text>}
    </Card>
  );
}, (prevProps, nextProps) => {
  // Custom comparison function
  return prevProps.value === nextProps.value && 
         prevProps.isRunning === nextProps.isRunning;
});