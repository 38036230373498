// File: components/dashboard/ActivityItem.jsx
import React from 'react';
import { Text } from "@tremor/react";

export const ActivityItem = React.memo(({ 
  message, 
  timestamp 
}) => {
  console.log(`ActivityItem rendered: ${message.slice(0, 20)}...`); // Debugging render

  const getMessageStyle = (message) => {
    if (message.includes('Processing')) return 'text-blue-600';
    if (message.includes('Error')) return 'text-red-600';
    if (message.includes('success')) return 'text-green-600';
    return '';
  };

  return (
    <div className="flex justify-between py-2 border-b">
      <div className="flex-1 pr-4">
        <Text className={`whitespace-pre-wrap break-words ${getMessageStyle(message)}`}>
          {message}
        </Text>
      </div>
      <Text className="text-sm text-gray-500 whitespace-nowrap ml-2">
        {new Date(timestamp).toLocaleString()}
      </Text>
    </div>
  );
}, (prevProps, nextProps) => {
  // Chỉ re-render khi message hoặc timestamp thay đổi
  return prevProps.message === nextProps.message && 
         prevProps.timestamp === nextProps.timestamp;
});